<template>
  <div class="addKepalaKel row justify-content-center">
    <form class="col-sm-10" @submit.prevent="AddKeluarga">
      <div class="title justify-content-center mb-3">
        <img src="../assets/GerejaToraja.png" alt />
        <h1>Tambahkan Keluarga</h1>
      </div>
      <div class="form-row justify-content-center">
        <div class="form-group col-md-3">
          <label for="sektor">Sektor</label>
          <select
            id="sektor"
            v-model="SektorId"
            @change="fatctKeluargaSektor"
            class="form-control"
            required
          >
            <option
              v-for="sektor in sektorsList"
              :key="sektor.id"
              :value="sektor.id"
              @change="fatctKeluargaSektor"
            >{{sektor.namaSektor}}</option>
          </select>
        </div>
        <div class="form-group col-md-6">
          <label for="Kepala Keluarga">Nama keluarga</label>
          <input id="Kepala Keluarga" type="text" v-model="namaKel" class="form-control" required />
        </div>
      </div>
      <button type="submit" class="btn btn-primary">Tambahkan</button>
    </form>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">Nama Keluarga</th>
          <th scope="col">Jumlah Anggota</th>
          <!-- <th scope="col">Lihat</th> -->
        </tr>
      </thead>
      <tbody scope="row" v-for="keluarga in this.keluargas" :key="keluarga.id">
        <tr>
          <th>{{keluarga.namaKel}}</th>
          <td>{{keluarga.Anggota.length}}</td>
        </tr>
      </tbody>
    </table>
    <!-- {{ this.keluargas }} -->
  </div>
</template>

<script>
import axios from "../API/axios";

export default {
  name: "AddKepalaKel",
  data() {
    return {
      SektorId: 0,
      namaKel: "",
      keluargas: []
    };
  },
  methods: {
    AddKeluarga() {
      axios
        .post("/database/keluarga", {
          namaKel: this.namaKel,
          SektorId: +this.SektorId
        })
        .then(data => {
          console.log(data, "ini data");
          this.$router.push("/");
        });
    },
    fatctKeluargaSektor(e) {
      axios.get(`/database/keluarga/sektor/${e.target.value}`).then(data => {
        console.log(data, "Keluarga/Sektor");
        this.keluargas = data.data;
      });
    }
  },
  computed: {
    sektorsList() {
      return this.$store.state.sektors;
    }
  },
  created() {
    this.$store.dispatch("fatchSektor");
  }
};
</script>

<style scoped>
.title {
  font-family: "Oswald", sans-serif;
}
img {
  width: 200px;
}

@media screen and (max-width: 800px) {
  .title {
    font-family: "Oswald", sans-serif;
    margin-bottom: 30px;
  }
  img {
    width: 100px;
  }
  h1 {
    font-size: 25px;
    font-weight: bold;
  }
}
</style>