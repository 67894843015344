import Vue from 'vue'
import Vuex from 'vuex'
import axios from "../API/axios"
import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    sektors: []
  },
  mutations: {

    listSektor(state, data) {
      state.sektors = data
      // console.log(data);
    },
  },
  actions: {
    fatchSektor(context, payload) {
      axios.get("/database/sektor")
        .then(({ data }) => {
          context.commit("listSektor", data)
          // console.log(data)
        })
        .catch(err => {
          console.log(err);
        })
    }
  },
  modules: {
  }
})
