import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import AddAnggota from "../views/AddAnggota.vue"
import Database from "../views/Database.vue"
import AddKepalaKel from "../views/AddKepalaKel.vue"
import AnggotaKeluarga from "../views/AnggotaKeluarga.vue"
import DaftarAnggota from "../views/DaftarAnggota.vue"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/addanggota',
    name: 'AddAnggota',
    component: AddAnggota
  },
  {
    path: '/addkk',
    name: 'AddKepalaKel',
    component: AddKepalaKel
  },
  {
    path: '/anggota',
    name: 'DaftarAnggota',
    component: DaftarAnggota
  },
  {
    path: '/anggotakeluarga',
    name: 'AnggotaKeluarga',
    component: AnggotaKeluarga
  },
  {
    path: '/database',
    name: 'Database',
    component: Database
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
