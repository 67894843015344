<template>
  <div class="AnggotaList">
    <div class="title justify-content-center mb-3">
      <img src="../assets/GerejaToraja.png" alt />
      <h1>Anggota Keluarga</h1>
    </div>

    <div class="form-row justify-content-center">
      <div class="form-group col-md-3">
        <label for="sektor">Sektor</label>
        <select id="sektor" v-model="SektorId" @change="fatctKeluargaSektor" class="form-control">
          <option
            v-for="sektor in sektorsList"
            :key="sektor.id"
            :value="sektor.id"
          >{{sektor.namaSektor}}</option>
        </select>
      </div>
      <div class="form-group col-md-4">
        <label for="Kepala Keluarga">Daftar Anggota Keluarga</label>
        <select
          id="Kepala Keluarga"
          v-model="KeluargaId"
          @change="fatchAnggota"
          class="form-control"
        >
          <option
            v-for="keluarga in keluargas"
            :key="keluarga.id"
            :value="keluarga.id"
          >{{keluarga.namaKel}}</option>
        </select>
      </div>
    </div>
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nama Lengkap</th>
          <th scope="col">Nama Panggilan</th>
          <th scope="col">Tempat - Tgl Lahir</th>
          <th scope="col">Alamat</th>
          <th scope="col">Hubungan</th>
          <th scope="col">Pekerjaan</th>
          <th scope="col">Status Pernikahan</th>
          <th scope="col">Status Jemaat</th>
          <th scope="col">Status Sidi</th>
          <!-- <th scope="col">Lihat</th> -->
        </tr>
      </thead>
      <tbody scope="row" v-for="anggota in this.Anggota" :key="anggota.id">
        <tr>
          <th>{{anggota.id}}</th>
          <th>{{anggota.namaLengkap}}</th>
          <td>{{anggota.namaPanggil}}</td>
          <td>{{anggota.tmptLhr}} - {{anggota.tglLahir.slice(0,10)}}</td>
          <td>{{anggota.alamat}}</td>
          <td>{{anggota.hubungan}}</td>
          <td>{{anggota.pekerjaan}}</td>
          <td>{{anggota.nikah}}</td>
          <td>{{anggota.statusJem}}</td>
          <td>{{anggota.statusAng}}</td>
        </tr>
      </tbody>
    </table>
    <!-- {{keluargas}} -->
    <!-- {{Anggota}} -->
  </div>
</template>

<script>
import axios from "../API/axios";
export default {
  name: "AnggotaList",
  data() {
    return {
      SektorId: 0,
      KeluargaId: 0,
      keluargas: [],
      Anggota: []
    };
  },
  computed: {
    sektorsList() {
      return this.$store.state.sektors;
    }
  },
  created() {
    this.$store.dispatch("fatchSektor");
  },
  methods: {
    fatctKeluargaSektor(e) {
      axios.get(`/database/keluarga/sektor/${e.target.value}`).then(data => {
        console.log(data, "Keluarga/Sektor");
        this.keluargas = data.data;
      });
    },
    fatchAnggota(e) {
      axios.get(`/database/anggota/keluarga/${e.target.value}`).then(data => {
        console.log(data);
        this.Anggota = data.data;
      });
    }
  }
};
</script>

<style scoped>
.table tbody {
  text-align: left;
}
table {
  font-size: 12px;
}

@media screen and (max-width: 800px) {
  table {
    display: none;
  }
}
</style>