<template>
  <div class="DaftarAnggota">
    <div data-aos="zoom-out-up" data-aos-duration="1500" class="title justify-content-center mb-3">
      <img src="../assets/GerejaToraja.png" alt />
      <h1>Daftar Anggota</h1>
    </div>
    <table
      data-aos="zoom-in-up"
      data-aos-duration="1000"
      data-aos-delay="1500"
      class="table table-hover"
    >
      <thead>
        <tr>
          <th scope="col">ID</th>
          <th scope="col">Nama Lengkap</th>
          <th scope="col">Tempat - Tgl Lahir</th>
          <th scope="col">Jenis Kelamin</th>
          <th scope="col">Alamat</th>
          <th scope="col">Sektor</th>
          <th scope="col">Kepala Kel.</th>
          <th scope="col">Pekerjaan</th>
          <th scope="col">Status Pernikahan</th>
          <!-- <th scope="col">Lihat</th> -->
        </tr>
      </thead>
      <tbody scope="row" v-for="anggota in this.anggota" :key="anggota.id">
        <tr>
          <td>{{anggota.id}}</td>
          <td>{{anggota.namaLengkap}}</td>
          <td>{{anggota.tmptLhr }} - {{anggota.tglLahir.slice(0,10)}}</td>
          <td>{{anggota.jnsKelamin }}</td>
          <td>{{anggota.alamat }}</td>
          <td>{{anggota.Sektor.namaSektor }}</td>
          <td>{{kosong(anggota.Keluarga)}}</td>
          <td>{{anggota.pekerjaan}}</td>
          <td>{{anggota.nikah}}</td>
        </tr>
        <!-- sasa -->
      </tbody>
    </table>
  </div>
</template>

<script>
import axios from "../API/axios";
export default {
  name: "DaftarAnggota",
  data() {
    return {
      anggota: []
    };
  },
  methods: {
    fetchAnggota() {
      axios.get("/database/anggota").then(data => {
        this.anggota = data.data;
      });
    },
    kosong(e) {
      console.log(e, "eee");
      let hasil = "";
      if (!e) {
        hasil = "Belum Masuk Keluarga";
      } else {
        hasil = e.namaKel;
      }
      return hasil;
    }
  },
  created() {
    this.fetchAnggota();
    // this.kosong();
  }
};
</script>

<style scoped>
.table td {
  /* background-color: beige; */
  padding: 5px;
}

table {
  font-size: 11px;
}
.table tbody {
  text-align: left;
}
.title {
  font-family: "Oswald", sans-serif;
}
img {
  width: 200px;
}

@media screen and (max-width: 800px) {
  .title {
    font-family: "Oswald", sans-serif;
    margin-bottom: 30px;
  }
  img {
    width: 100px;
  }
  h1 {
    font-size: 20px;
    font-weight: bold;
  }
  table {
    display: none;
  }
}
</style>