<template>
  <div class="Database">
    <div data-aos="zoom-out-up" data-aos-duration="1500" class="title">
      <img src="../assets/GerejaToraja.png" alt />
      <p>Database Anggota</p>
    </div>
    <!-- Body -->
    <div data-aos="zoom-in-up" data-aos-duration="1000" data-aos-delay="1500" class="data">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">Nama Sektor</th>
            <th scope="col">Jumlah KK</th>
            <th scope="col">Jumlah Anggota</th>
            <!-- <th scope="col">Lihat</th> -->
          </tr>
        </thead>
        <tbody scope="row" v-for="sektor in this.sektorList" :key="sektor.id" :value="sektor.id">
          <tr>
            <th>{{sektor.namaSektor}}</th>
            <td>{{sektor.Keluargas.length}}</td>
            <td>{{sektor.Anggota.length}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Button trigger modal -->

    <!-- {{this.sektorList}} -->
  </div>
</template>

<script>
import axios from "../API/axios";

export default {
  name: "Database",
  data() {
    return {
      sektors: [],
      anggotas: []
    };
  },
  methods: {
    getAllAnggota() {
      axios
        .get("/database/anggota")
        .then(data => {
          this.anggotas = data;
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  computed: {
    sektorList() {
      return this.$store.state.sektors;
    }
  },
  created() {
    this.getAllAnggota();
    this.$store.dispatch("fatchSektor");
  }
};
</script>

<style scoped>
button {
  font-size: 12px;
  margin: 0 1px;
}
table {
  border: none;
}
img {
  width: 200px;
}
p {
  font-size: 30px;
  font-family: "Oswald", sans-serif;
}

@media screen and (max-width: 800px) {
  img {
    width: 100px;
  }
  table {
    font-size: 12px;
  }

  button {
    font-size: 8px;
  }
}
</style>