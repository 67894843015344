<template>
  <div class="AddAnggota">
    <form class="col-sm-10" @submit="addAnggota">
      <div
        data-aos="zoom-out-up"
        data-aos-duration="1500"
        class="title justify-content-center mb-3"
      >
        <img src="../assets/GerejaToraja.png" alt />
        <h1>Formulir Pendaftaran Anggota</h1>
      </div>
      <!-- line1 -->
      <div
        class="container-fluid"
        data-aos="fade-right"
        data-aos-duration="1000"
        data-aos-delay="1000"
      >
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="NIK">NIK</label>
            <input type="text" class="form-control" id="NIK" v-model="nik" />
          </div>
          <div class="form-group col-md-4">
            <label for="Nama Panjang">Nama Lengkap</label>
            <input
              type="text"
              class="form-control"
              id="Nama Panjang"
              v-model="namaLengkap"
              required
            />
          </div>
          <div class="form-group col-md-4">
            <label for="Nama Panggilan">Nama Panggilan</label>
            <input type="text" class="form-control" id="Nama Panggilan" v-model="namaPanggil" />
          </div>
        </div>
        <!-- Line 2 -->
        <div class="form-row justify-content-center">
          <div class="form-group col-md-3">
            <label for="sektor">Sektor</label>
            <select
              id="sektor"
              v-model="SektorId"
              @change="fatctKeluargaSektor"
              class="form-control"
              required
            >
              <option
                v-for="sektor in sektorsList"
                :key="sektor.id"
                :value="sektor.id"
              >{{sektor.namaSektor}}</option>
            </select>
          </div>
          <div class="form-group col-md-6">
            <label for="Kepala Keluarga">Kepala keluarga</label>
            <select id="Kepala Keluarga" v-model="KeluargaId" class="form-control">
              <option
                v-for="keluarga in keluargas"
                :key="keluarga.id"
                :value="keluarga.id"
              >{{keluarga.namaKel}}</option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label for="Hubn.Kel">Hub Keluarga</label>
            <select id="Hubn.Kel" v-model="hubungan" class="form-control" required>
              <option v-for="hub in HubKeluarga" :key="hub" :value="hub">{{hub}}</option>
            </select>
          </div>
        </div>
        <!-- Line 3 -->
        <div class="form-row justify-content-center">
          <div class="form-group col-md-3">
            <label for="inputState">Jen. Kelamin</label>
            <select v-model="jnsKelamin" id="inputState" class="form-control" required>
              <option value="Laki-Laki">Laki-Laki</option>
              <option value="perempuan">perempuan</option>
            </select>
          </div>
          <div class="form-group col-md-9">
            <label for="inputAddress">Alamat</label>
            <input
              v-model="alamat"
              type="text"
              class="form-control"
              id="inputAddress"
              placeholder="ex: Jl. Rappocini Lr....."
              required
            />
          </div>
        </div>
        <!-- Line4 -->
        <div class="form-row">
          <div class="form-group col-md-4">
            <label for="tempat lahir">Tempat Lahir</label>
            <input type="text" v-model="tmptLhr" class="form-control" id="tempat lahir" />
          </div>
          <div class="form-group col-md-4">
            <label for="Tgl Lahir">Tanggal Lahir</label>
            <input type="date" v-model="tglLahir" class="form-control" id="Tgl Lahir" required />
          </div>
          <div class="form-group col-md-4">
            <label for="Gol darah">Gol. darah</label>
            <select id="Gol darah" v-model="golDarah" class="form-control" required>
              <option
                v-for="golongan in GolonganDarah"
                :key="golongan"
                :value="golongan"
              >{{golongan}}</option>
            </select>
          </div>
        </div>

        <!-- Line5 -->
        <div class="form-row justify-content-around">
          <div class="form-group col-md-3">
            <label for="No. Tlpn">No. Telepon</label>
            <input type="text" class="form-control" v-model="noHp" id="No. Tlpn" />
          </div>
          <div class="form-group col-md-3">
            <label for="pekerjaan">Pekerjaan</label>
            <select id="pekerjaan" v-model="pekerjaan" class="form-control">
              <option v-for="hub in NamaPekerjaan" :key="hub" :value="hub">{{hub}}</option>
            </select>
          </div>
          <div class="form-group col-md-3">
            <label for="pekerjaan">Pend. Terakhir</label>
            <select id="pekerjaan" v-model="pendidikan" class="form-control">
              <option v-for="hub in Pendidikan" :key="hub" :value="hub">{{hub}}</option>
            </select>
          </div>
        </div>

        <!-- Line 6 -->
        <p id="statJem">STATUS JEMAAT</p>
        <div class="form-row justify-content-center">
          <div class="form-group col-md-4">
            <label for="statusJemaat">Status Jemaat</label>
            <select id="statusJemaat" class="form-control" v-model="statusJem" required>
              <option v-for="status in statJem" :key="status" :value="status">{{status}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="Status-Anggota">Status Keanggotaan</label>
            <select id="Status-Anggota" class="form-control" v-model="statusAng" required>
              <option v-for="status in statAngota" :key="status" :value="status">{{status}}</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="nikah">Stat. Pernikahan</label>
            <select id="nikah" v-model="nikah" class="form-control" required>
              <option v-for="status in statKawin" :key="status" :value="status">{{status}}</option>
            </select>
          </div>
        </div>

        <!--  Line 7  -->
        <div class="form-row justify-content-around">
          <div class="form-group col-md-4">
            <label for="domisili">Status Domisili</label>
            <select id="domisili" v-model="domisili" class="form-control" required>
              <option value="DALAM JEMAAT">DALAM JEMAAT</option>
              <option value="LUAR JEMAAT">LUAR JEMAAT</option>
            </select>
          </div>
          <div class="form-group col-md-4">
            <label for="diakonia">Status Diakonia</label>
            <select id="diakonia" v-model="diakonia" class="form-control" required>
              <option value="ANGGOTA DIAKONIA">ANGGOTA DIAKONIA</option>
              <option value="BUKAN DIAKONIA">BUKAN DIAKONIA</option>
            </select>
          </div>
        </div>
        <!-- Line 8 -->
        <div class="Line8">
          <div class="line8 form-row justify-content-center mt-3">
            <div class="form-group col-md-4 mt-3">
              <p>KELOMPOK OIG</p>
              <div class="row justify-content-center">
                <input v-model="oigSMGT" type="checkbox" id="oigSMGT" name="oigSMGT" value="smgt" />
                <label for="oigSMGT">SMGT</label>
                <br />
                <input v-model="oigPPGT" type="checkbox" id="oigPPGT" name="oigPPGT" value="PPGT" />
                <label for="oigPPGT">PPGT</label>
                <br />
                <input v-model="oigPWGT" type="checkbox" id="oigPWGT" name="oigPWGT" value="Boat" />
                <label for="oigPWGT">PWGT</label>
                <input
                  v-model="oigPKBGT"
                  type="checkbox"
                  id="oigPKBGT"
                  name="oigPKBGT"
                  value="Boat"
                />
                <label for="oigPKBGT">PKBGT</label>
              </div>
            </div>

            <div class="form-group col-md-4 mt-3">
              <p>PEL. KEBAKTIAN</p>
              <div class="row justify-content-center">
                <input v-model="kebJem" type="checkbox" id="kebJem" name="kebJem" value="smgt" />
                <label for="kebJem">JEMAAT</label>
                <br />
                <input v-model="kebPPGT" type="checkbox" id="kebPPGT" name="kebPPGT" value="PPGT" />
                <label for="kebPPGT">PPGT</label>
                <br />
                <input v-model="kebPWGT" type="checkbox" id="kebPWGT" name="kebPWGT" value="Boat" />
                <label for="kebPWGT">PWGT</label>
                <input
                  v-model="kebPKBGT"
                  type="checkbox"
                  id="kebPKBGT"
                  name="kebPKBGT"
                  value="Boat"
                />
                <label for="kebPKBGT">PKBGT</label>
              </div>
            </div>
            <div class="form-group col-md-4 mt-3">
              <p>PERSEMBAHAN BULANAN</p>
              <div class="row justify-content-center">
                <input v-model="amplJem" type="checkbox" id="amplJem" name="amplJem" value="smgt" />
                <label for="amplJem">JEMAAT</label>
                <input
                  v-model="amplPWGT"
                  type="checkbox"
                  id="amplPWGT"
                  name="amplPWGT"
                  value="Boat"
                />
                <label for="amplPWGT">PWGT</label>
                <input
                  v-model="amplPKBGT"
                  type="checkbox"
                  id="amplPKBGT"
                  name="amplPKBGT"
                  value="PKBGT"
                />
                <label for="amplPKBGT">PKBGT</label>
              </div>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Tambahkan</button>
      </div>
    </form>
  </div>
</template>

<script>
import axios from "../API/axios";
export default {
  name: "AddANggota",
  data() {
    return {
      sektors: [],
      keluargas: [],
      HubKeluarga: [
        "Kepala Keluarga",
        "Istri",
        "Suami",
        "Anak",
        "Orang Tua",
        "Mertua",
        "Saudara",
        "Ipar",
        "kemenakan",
        "Cucu",
        "Family Lainnya"
      ],
      JenisKelamin: ["Laki-Laki", "Perempuan"],
      GolonganDarah: ["0", "A", "B", "AB", "Tidak Tahu"],
      NamaPekerjaan: [
        "PNS",
        "BUMN",
        "Peg. Swasta",
        "Wirausaha",
        "TNI/POLRI",
        "Petani/Peternak",
        "Ibu Rumah Tangga",
        "Tidak bekerja",
        "Buruh",
        "Belum Bekerja",
        "Sementara Studi",
        "Pensiunan",
        "Honorer",
        "Jasa"
      ],
      Pendidikan: [
        "TK",
        "SD",
        "SMP",
        "SMA",
        "Diploma",
        "S1",
        "S2",
        "S3",
        "Tidak Sekolah",
        "Belum Sekolah"
      ],
      statJem: ["Anggota jemaat", "Bukan Anggota Jemaat"],
      statAngota: [
        "Anggota Sidi-Dewasa",
        "Anggota Baptis",
        "Ang. Calon Baptis"
      ],
      statKawin: ["Menikah", "Belum Nikah", "Janda", "Duda", "Belum diberkati"],
      nik: "",
      namaPanggil: "",
      namaLengkap: "",
      SektorId: 0,
      KeluargaId: 0,
      hubungan: "",
      jnsKelamin: "",
      tmptLhr: "",
      tglLahir: "",
      golDarah: "",
      noHp: "",
      alamat: "",
      pekerjaan: "",
      pendidikan: "",
      statusJem: "",
      statusAng: "",
      nikah: "",
      domisili: "",
      diakonia: "",
      amplJem: "",
      amplPWGT: "",
      amplPKBGT: "",
      kebJem: "",
      kebPPGT: "",
      kebPWGT: "",
      kebPKBGT: "",
      oigSMGT: "",
      oigPPGT: "",
      oigPWGT: "",
      oigPKBGT: ""
    };
  },
  computed: {
    sektorsList() {
      return this.$store.state.sektors;
    }
  },
  methods: {
    fatctKeluargaSektor(e) {
      axios.get(`/database/keluarga/sektor/${e.target.value}`).then(data => {
        console.log(data, "Keluarga/Sektor");
        this.keluargas = data.data;
      });
    },
    addAnggota() {
      let payload = {
        nik: this.nik,
        namaPanggil: this.namaPanggil,
        namaLengkap: this.namaLengkap,
        SektorId: this.SektorId,
        KeluargaId: this.KeluargaId,
        hubungan: this.hubungan,
        jnsKelamin: this.jnsKelamin,
        tmptLhr: this.tmptLhr,
        tglLahir: this.tglLahir,
        golDarah: this.golDarah,
        noHp: this.noHp,
        alamat: this.alamat,
        pekerjaan: this.pekerjaan,
        pendidikan: this.pendidikan,
        statusJem: this.statusJem,
        statusAng: this.statusAng,
        nikah: this.nikah,
        domisili: this.domisili,
        diakonia: this.diakonia,
        amplJem: this.amplJem,
        amplPWGT: this.amplPWGT,
        amplPKBGT: this.amplPKBGT,
        kebJem: this.kebJem,
        kebPPGT: this.kebPPGT,
        kebPWGT: this.kebPWGT,
        kebPKBGT: this.kebPKBGT,
        oigSMGT: this.oigSMGT,
        oigPPGT: this.oigPPGT,
        oigPWGT: this.oigPWGT,
        oigPKBGT: this.oigPKBGT
      };
      axios
        .post("/database/anggota", payload)
        .then(data => {
          console.log(payload);
          alert("Terima Kasih, data Anda Telah Masuk");
          console.log(data);
        })
        .catch(err => {
          console.log(err);
        });
    }
  },
  created() {
    this.$store.dispatch("fatchSektor");
  }
};
</script>

<style scoped>
* {
  margin: 5px 0px;
}
form {
  padding: 0;
  /* border: black 1px solid; */
  margin-bottom: 10px;
}

#statJem {
  margin-top: 25px;
  font-size: 25px;
  font-weight: bold;
}

button {
  margin-top: 7px;
  padding: 4px 10px;
}

h1 {
  margin-bottom: 10px;
  margin-top: 2px;
  font-family: "Oswald", sans-serif;
}

.AddAnggota {
  display: flex;
  justify-content: center;
  /* background-color: rgb(225, 226, 226); */
}
p {
  font-size: 20px;
}
img {
  width: 130px;
}

@media screen and (max-width: 800px) {
  form {
    margin-top: 15px;
  }
  * {
    padding: 0x;
    /* font-size: 12px; */
  }

  h1 {
    font-size: 25px;
    font-weight: bold;
  }
  label {
    font-weight: bold;
    font-size: 14px;
    text-align: left;
  }
  input {
    font-size: 10px;
  }
  img {
    width: 100px;
  }
  h1 {
    font-size: 20px;
    margin: 0;
  }
}
</style>